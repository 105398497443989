<template lang="pug">
#video-stage(:class="{ 'video-call': showVideoCallUI, 'landscape-secondary': landscapeSecondary }")
  ResumeVideo(v-if="showResume", @startVideo="startVideo")

  #notch-filler(v-show="!video.fullScreen")
  #nav-header
    VideoStageHeader(
      v-if="!video.fullScreen",
      @complete="gotoNext",
      @pauseVideo="pauseVideo()",
      @resumeVideo="resumeVideo()",
      @gotoVideo="gotoVideo",
      :title="video.title",
      :prompt="video.prompt",
      :step="video.step",
      :canGoNext="canGoNext",
      :actionButton="video.actionButton",
      :data="data",
      :videoProgressMarker="videoProgressMarker",
      :currentVideoIndex="currentVideoIndex",
      :nudgeActive="nudgeActive",
      :stepIndex="currentVideoIndex",
      :proceededWithoutVideo="proceededWithoutVideo"
    )
  #progress-bar
    div(
      :class="['bar', currentVideoIndex >= video ? 'completed-bar' : '']",
      v-for="video in videoLength",
      :key="video"
    )
  div(
    :class="[video.fullScreen ? 'vimeo-controls-fullscreen' : 'vimeo-controls', showInstructions ? 'chapter-break' : '']",
    @click="showVideoControls"
  )
    .vimeo-control-row
      .fullscreen-top-controls
        TimerButton(
          v-if="video.fullScreen && video.actionButton.type === 'timer'",
          @complete="gotoNext()",
          canStartTimer,
          autoStart,
          :duration="video.actionButton.duration"
        )
    .vimeo-control-row
      .vimeo-buttons
        div
          icon#vimeo-backward.vimeo-button(
            v-show="showVideoButton && !videoPaused && !video.fullScreen",
            data="@icon/vimeo-backward.svg",
            @click="moveVideo(-5)"
          )
        div
          icon#vimeo-play.vimeo-button(
            v-show="videoPaused && !showInstructions",
            data="@icon/vimeo-play.svg",
            @click="resumeVideo"
          )

          icon#vimeo-pause.vimeo-button(
            v-show="showVideoButton && !videoPaused && !video.fullScreen",
            data="@icon/vimeo-pause.svg",
            @click="pauseVideo"
          )

          icon#vimeo-replay.vimeo-button(
            v-show="showInstructions && (!instructions || instructions.length == 0 || proceededWithoutVideo)",
            data="@icon/vimeo-replay.svg",
            @click="replay"
          )
        div
          icon#vimeo-forward.vimeo-button(
            v-show="showVideoButton && !videoPaused && !video.fullScreen",
            data="@icon/vimeo-forward.svg",
            @click="moveVideo(5)"
          )
    .vimeo-control-row
      .fullscreen-bottom-controls(v-if="video.fullScreen")
        button#fs-gonext(
          v-if="video.actionButton.type !== 'timer'",
          @click="gotoNext()",
          :disabled="!canGoNext"
        ) Next
          icon(data="@icon/arrow-forward.svg")

      span.vimeo-text(v-else, v-show="showVideoButton && !videoPaused") {{ stepProgressText }}

  #mobile-video-player
    #video-player(:class="video.fullScreen ? 'vimeo-video-fullscreen' : 'vimeo-video'")

  #desktop-video-player
    #video-player.vimeo-video

  #user-vid(
    :class="cameraClasses",
    v-show="(!video.fullScreen && !proceededWithoutVideo) || showVideoCallUI || showCameraUI"
  )
    #user-vid-wrapper(ref="userVidWrapper")
    component.video-overlay(v-if="showVideoOverlay", :is="video.overlay")

    #user-vid-corners
      #uvc-top-left.uvc-corner
      #uvc-top-right.uvc-corner
      #uvc-bottom-left.uvc-corner
      #uvc-bottom-right.uvc-corner

  #user-vid-recording-indicator(v-if="showRecordingIndicator && !proceededWithoutVideo")
    #user-vid-recording-dot
    span REC

  #photo-controls(@click="handleCameraClick", v-if="showCameraUI")
    icon(data="@icon/photo-camera.svg")

  component.camera-overlay(v-if="showPhotoOverlay", :is="video.capturePhoto.overlay")

  #video-instructions(
    v-if="instructionsDisplay",
    :class="{ 'landscape-secondary': landscapeSecondary }"
  )
    .instructions-container
      .vi-title
        h1 Follow These Steps

        .replay(@click="replay", v-if="canGoNext")
          icon(data="@icon/rewind.svg")
          |
          | Replay

      transition-group(ref="stepsDisplay", name="fade")
        .instruction(v-for="(instruction, index) in instructions", :key="index + 1")
          img.img(
            :src="proceededWithoutVideo ? instruction.image : getMediaUrl(instruction.image)"
          )
          .test.step-index {{ index + 1 }}.
          .text {{ instruction.text }}

  VideoStageNav(
    v-if="!video.fullScreen",
    @complete="gotoNext",
    @pauseVideo="pauseVideo()",
    @resumeVideo="resumeVideo()",
    @gotoVideo="gotoVideo",
    :title="video.title",
    :prompt="video.prompt",
    :step="video.step",
    :canGoNext="canGoNext",
    :actionButton="video.actionButton",
    :data="data",
    :videoProgressMarker="videoProgressMarker",
    :currentVideoIndex="currentVideoIndex",
    :nudgeActive="nudgeActive",
    :stepIndex="currentVideoIndex",
    :proceededWithoutVideo="proceededWithoutVideo"
  )

  //- Video Call UI
  ConnectingToAgent(
    v-if="$app.showCallConnecting",
    :step="this.video.title",
    :checkReconnect="reconnect",
    :getMediaTracks="getMediaTracks",
    :disableLocalTracks="disableLocalTracks",
    @helpRequested="initUserVideo"
  )
  #support-video-call(v-show="showVideoCallUI")
  icon#support-video-call-end(
    v-if="showVideoCallUI",
    data="@icon/call_end.svg",
    @click="endVideoCall"
  )

  #support-video-call-end-desktop(v-if="showVideoCallUI", @click="endVideoCall")
    .button End Call

  //- Using a built-in modal instead of a regular modal, which cannot load when network is disconnected
  ConnectionWarning(v-show="showWarning", :action="reconnect")

  //- Hidden elements
  canvas#photo-canvas(v-show="false", ref="photoCanvas")
  audio#camera-shutter(ref="cameraShutter", src="~@/assets/audio/shutter.mp3")
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import player from '@vimeo/player'
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'
import {
  runPreflight,
  connect,
  createLocalVideoTrack,
  createLocalAudioTrack,
  LocalDataTrack,
} from 'twilio-video'
import Email from '@/utils/email'

const CC_BASE_API_URL = process.env.VUE_APP_CC_API_URL
const encouragmentAudioFiles = [
  'are-you-ready',
  'make-sure-you-are-following-instructions',
  'now-you-can-give-it-a-try',
  'try-to-stay-in-frame',
  'you-give-it-a-try',
  'your-turn',
]

export default {
  name: 'VideoStage',
  props: {
    minNetworkQuality: { type: Number, default: 3 },
    stopOnPreflightFail: { type: Boolean, default: true },
    data: { type: Array, required: true, default: () => [] },
    videoID: { type: String, required: true },
  },

  data() {
    return {
      instructionsPointer: 0,
      currentVideoIndex: 0,
      videoProgressMarker: 0,
      showResume: false,
      userVideo: null,
      userCameraFacing: 'user',
      mediaRootUrl: process.env.VUE_APP_MEDIA_ROOT_URL,
      player: null,
      video: {
        title: null,
        prompt: null,
        step: null,
        title: null,
        actionButton: null,
        instructions: null,
        confirmationDialog: null,
        capturePhoto: null,
        fullScreen: false,
        overlay: null,
        userCameraFacing: 'user',
      },
      encouragementAudio: null,
      videoChapters: null,
      videoPaused: true,
      showVideoButton: true,
      hideVideoButton: null,
      stepProgressText: '--:-- / --:--',
      canGoNext: false,
      showInstructions: false,
      hasConfirmed: false,
      hasCapturedPhoto: false,
      nudgeActive: false,
      showCameraUI: false,
      showVideoCallUI: false,
      room: null,
      callAnswers: null,
      stepTimeStamp: null,
      stepTimeStampAmplitude: Date.now(),
      showWarning: false,
      instructions: null,
      connectionMonitor: null,
      landscapeSecondary: window.orientation == -90,
    }
  },

  computed: {
    ...mapState({
      localVideoTrack: state => state.twilio.localVideoTrack,
      localAudioTrack: state => state.twilio.localAudioTrack,
      localDataTrack: state => state.twilio.localDataTrack,
    }),
    ...mapGetters({
      medplumOrderId: 'user/getMedplumOrderId',
      barcodeId: 'user/getBarcodeId',
      kitType: 'user/getKitType',
      isIOS15: 'user/getIsIOS15',
      proceededWithoutVideo: 'user/getVideoPermission',
      configKeys: 'user/getConfigKeys',
    }),
    cameraClasses,
    instructionsDisplay,
    showPhotoOverlay,
    showRecordingIndicator,
    showVideoOverlay,
    videoLength,
  },

  watch: {},

  async created() {
    window.addEventListener('orientationchange', () => {
      this.landscapeSecondary = window.orientation == -90
    })

    this.$app.forceLandscape = true

    // check for video index saved to localStorage
    const savedVideoIndex = parseInt(localStorage.getItem('currentVideoIndex'))
    if (savedVideoIndex) {
      this.video = { ...this.data[savedVideoIndex] }
      this.currentVideoIndex = savedVideoIndex
      this.showResume = true
    } else {
      this.video = { ...this.data[0] }
    }

    const savedVideoProgressMarker = parseInt(localStorage.getItem('videoProgressMarker'))
    if (savedVideoProgressMarker) {
      this.videoProgressMarker = savedVideoProgressMarker
    } else {
      this.videoProgressMarker = this.currentVideoIndex
      localStorage.setItem('videoProgressMarker', this.videoProgressMarker)
    }

    this.userCameraFacing = this.video.userCameraFacing
  },

  mounted() {
    this.setupVideoPlayer().then(() => {
      if (!this.showResume) {
        this.startVideo()
      }
    })
  },

  beforeDestroy() {
    if (this.player) {
      this.player.destroy()
    }

    this.$app.forceLandscape = false
  },

  methods: {
    capitalizeTitle,
    getMediaUrl,
    capturePhoto,
    complete,
    endVideoCall,
    gotoNext,
    gotoVideo,
    handleCameraClick,
    handleConfirmationComplete,
    handlePhotoApproved,
    initTakePhoto,
    nextVideo,
    pauseVideo,
    replay,
    resumeVideo,
    moveVideo,
    setupVideoPlayer,
    showConfirmation,
    showSupportCallRequest,
    startVideo,
    startVideoCall,
    handleMediaError,
    getMediaTracks,
    initTwilio,
    reconnect,
    restartTrack,
    roomDisconnected,
    sendMessage,
    initUserVideo,
    disableLocalTracks,
    showInformative,
    showRemoveBatteries,
    showVideoControls,
  },

  components: {
    TimerButton: require('@/components/TimerButton').default,
    ConnectingToAgent: require('@/steps/ConnectingToAgent').default,
    VideoStageNav: require('@/components/VideoStageNav').default,
    VideoStageHeader: require('@/components/VideoStageHeader').default,
    ConnectionWarning: require('@/components/modals/ConnectionWarning').default,
  },
}

/* Computed ---------------------------------------------------- */
function videoLength() {
  return JSON.parse(localStorage.getItem('currentSection'))['steps'].find(
    step => step.component === 'VideoStage'
  )['config']['data'].length
}

function cameraClasses() {
  let classes = []

  if (this.showCameraUI) {
    classes.push('capture-photo')
  }

  if (this.showVideoCallUI) {
    classes.push('video-call')
  }

  if (this.video?.capturePhoto?.facing) {
    classes.push(`${this.video.capturePhoto.facing}-photo`)
  }

  const videoDirection = this.video.userCameraFacing || 'user'
  classes.push(`${videoDirection}-video`)

  return classes

  // {'capture-photo': showCameraUI, 'video-call': showVideoCallUI, video.capturePhoto.facing }
}

function instructionsDisplay() {
  return this.showInstructions && !!this.instructions?.length
}

function showPhotoOverlay() {
  return this.video.capturePhoto?.overlay && this.showCameraUI
}

function showRecordingIndicator() {
  return !this.video.fullScreen && !this.showCameraUI
}

function showVideoOverlay() {
  return !this.showCameraUI && this.video.overlay
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function _secToTime(seconds) {
  return new Date(seconds * 1000).toISOString().substr(14, 5)
}

function capitalizeTitle(title) {
  return title.replace(/\b[a-z]/g, c => c.toUpperCase())
}

function getMediaUrl(mediaFile) {
  return mediaFile.includes(this.mediaRootUrl) ? mediaFile : `${this.mediaRootUrl}${mediaFile}`
}

function showVideoControls() {
  this.showVideoButton = true

  clearTimeout(this.hideVideoButton)
  this.hideVideoButton = setTimeout(() => {
    this.showVideoButton = false
  }, 2000)
}

function capturePhoto() {
  if (this.video.capturePhoto.prompt) {
    this.$modal.open('PhotoCapturePrompt', {
      // rotate the photo for user-facing photos in forced landscape
      rotatePhoto: this.video.capturePhoto.facing != 'environment',
      prompt: this.video.capturePhoto.prompt,
      icon: this.video.capturePhoto.icon,
      cancelBtnText: this.video.capturePhoto.cancelBtnText,
      actionBtnText: this.video.capturePhoto.actionBtnText,
      onAction: () => {
        this.initTakePhoto()
      },
    })
  } else {
    this.initTakePhoto()
  }
}

function complete() {
  // window.clearInterval(this.connectionMonitor)
  // if (!this.proceededWithoutVideo) {
  //   this.localVideoTrack.stop()
  //   this.room.localParticipant.unpublishTrack(this.localVideoTrack)
  //   this.localVideoTrack.detach().forEach(element => element.remove())
  //   this.localAudioTrack.stop()
  //   this.room.localParticipant.unpublishTrack(this.localAudioTrack)
  //   this.localAudioTrack.detach().forEach(element => element.remove())
  // }
  // this.room.removeListener('disconnected', this.roomDisconnected)
  // this.room.disconnect()
  this.$emit('complete')
}

function gotoNext() {
  this.encouragementAudio?.pause()
  if (this.video.confirmationDialog && !this.hasConfirmed) {
    this.showConfirmation()
  } else if (this.video.informativeDialog) {
    this.showInformative()
  } else if (this.video.removeBatteriesModal) {
    this.showRemoveBatteries()
  } else if (this.video.capturePhoto && !this.hasCapturedPhoto) {
    this.capturePhoto()
  } else if (this.data.length == this.currentVideoIndex + 1) {
    this.complete()
    localStorage.removeItem('currentVideoIndex')
    localStorage.removeItem('videoProgressMarker')
  } else {
    if (this.video.specimenType) {
      this.$store.dispatch('user/collectSample', {
        specimenType: this.video.specimenType,
        specimenIndex:
          typeof this.video.specimenIndex !== 'undefined' ? this.video.specimenIndex : '1',
      })
    }
    this.nextVideo()
  }
}

function gotoVideo(videoIndex) {
  this.pauseVideo()
  this.nextVideo(videoIndex)
}

function sendMessage(message) {
  this.localDataTrack.send(JSON.stringify(message))
}

function endVideoCall() {
  this.sendMessage({ messageType: 'call-ended' })
  this.showVideoCallUI = false
}

async function handleCameraClick() {
  this.$refs.cameraShutter.play()

  this.showCameraUI = false
  this.takePhoto = false
  var video = this.userVideoEl
  var photoCanvas = this.$refs.photoCanvas
  var context = photoCanvas.getContext('2d')

  // set the canvas to have the same dimensions as the video
  context.canvas.width = video.videoWidth
  context.canvas.height = video.videoHeight

  var vRatio = (photoCanvas.height / video.videoHeight) * video.videoWidth
  context.clearRect(0, 0, photoCanvas.height, photoCanvas.width)
  context.drawImage(video, 0, 0, vRatio, video.videoHeight)

  // save image to localstorage so photo confirmation modal can display it
  localStorage.setItem('capturedImage', photoCanvas.toDataURL())

  this.$modal.open('PhotoConfirmation', {
    // rotate the photo for user-facing photos in forced landscape
    rotateImage: this.video.capturePhoto.facing != 'environment',
    title: this.video.capturePhoto.title,
    onComplete: this.handlePhotoApproved,
    onCancel: this.initTakePhoto,
  })
}

function handleConfirmationComplete() {
  this.hasConfirmed = true
  this.gotoNext()
}

async function handlePhotoApproved() {
  if (this.proceededWithoutVideo) {
    this.disableLocalTracks()
  }
  const filenamePrefix = this.video.capturePhoto.filenamePrefix
  await this.$refs.photoCanvas.toBlob(blob => {
    this.$store.dispatch('user/upload', {
      file: blob,
      fileName: `${filenamePrefix}-${Date.now()}.png`,
    })
  }, 'image/png')

  this.showCameraUI = false
  this.hasCapturedPhoto = true

  // pause and reset the camera shutter sound
  this.$refs.cameraShutter.pause()
  this.$refs.cameraShutter.currentTime = 0

  this.gotoNext()
}

function showConfirmation() {
  this.$modal.open('ConfirmationDialog', {
    ...this.video.confirmationDialog,
    onAction: this.handleConfirmationComplete,
  })
}

function showInformative() {
  this.$modal.open('InformativeDialog', {
    ...this.video.informativeDialog,
    onAction: this.nextVideo,
  })
}

function showRemoveBatteries() {
  this.$modal.open('BatteryConfirmation', {
    onAction: this.nextVideo,
  })
}

function showSupportCallRequest() {
  this.$modal.close('ConnectToAgent')
  this.$modal.show('Connect')
}

function initTakePhoto() {
  if (this.proceededWithoutVideo && !this.localVideoTrack) {
    createLocalVideoTrack({ facingMode: this.video.capturePhoto.facing })
      .then(track => {
        if (!this.localVideoTrack) {
          this.$store.commit('twilio/SET_LOCAL_VIDEO_TRACK', track)
        }
        this.initUserVideo()
        this.showCameraUI = true
      })
      .catch(error => {
        this.$modal.open('UpdateBrowserPermissions')
      })
  } else {
    this.showCameraUI = true
    // if capture facing doesn't match camera facing, restart the camera
    if (this.video.capturePhoto.facing !== this.userCameraFacing) {
      this.localVideoTrack.restart({ facingMode: this.video.capturePhoto.facing })
      this.userCameraFacing = this.video.capturePhoto.facing
    }
  }
}

function disableLocalTracks() {
  if (this.localAudioTrack) {
    this.localAudioTrack.stop()
    this.localAudioTrack.detach().forEach(element => element.remove())
    this.room.localParticipant.unpublishTrack(this.localAudioTrack)
    this.$store.commit('twilio/SET_LOCAL_AUDIO_TRACK', null)
  }
  if (this.localVideoTrack) {
    this.localVideoTrack.stop()
    this.localVideoTrack.detach().forEach(element => element.remove())
    this.room.localParticipant.unpublishTrack(this.localVideoTrack)
    this.$store.commit('twilio/SET_LOCAL_VIDEO_TRACK', null)
  }
}

async function nextVideo(videoIndex = null) {
  const eventTitle = this.video.title
  const eventProperties = {
    step: this.video.prompt,
    kit_type: this.kitType,
    medplum_id: this.medplumOrderId,
    sku: this.$store.getters['user/getSku'],
    customer: this.configKeys ? this.configKeys.carrier : null,
    barcode: this.barcodeId,
    segment_time: Date.now() - this.stepTimeStampAmplitude,
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })

  if (this.instructionsDisplay) {
    this.$refs['stepsDisplay'].$el.innerHTML = ''
  }

  this.stepTimeStampAmplitude = Date.now()
  this.instructionsPointer = 0
  this.instructions = []
  this.showVideoButton = false
  this.canGoNext = false
  this.hasConfirmed = false
  this.hasCapturedPhoto = false
  this.currentVideoIndex = videoIndex == null ? this.currentVideoIndex + 1 : videoIndex
  this.video = { ...this.data[this.currentVideoIndex] }
  localStorage.setItem('currentVideoIndex', this.currentVideoIndex)

  // Update step nav progress marker
  if (this.currentVideoIndex > this.videoProgressMarker) {
    this.videoProgressMarker = this.currentVideoIndex
    localStorage.setItem('videoProgressMarker', this.currentVideoIndex)
  }

  // Default to user facing camera if none is set
  const nextVideoCameraFacing = this.video.userCameraFacing ? this.video.userCameraFacing : 'user'

  if (!this.proceededWithoutVideo) {
    if (this.userCameraFacing !== nextVideoCameraFacing) {
      this.localVideoTrack.restart({ facingMode: nextVideoCameraFacing })
      this.userCameraFacing = nextVideoCameraFacing
    }
  }

  this.player
    .setCurrentTime(this.videoChapters[this.currentVideoIndex].startTime)
    .then(_ => this.player.play())
  this.stepTimeStamp = Date.now()
  // this.sendMessage({
  //   messageType: 'user-step',
  //   data: {
  //     title: this.video.title,
  //     prompt: this.video.prompt,
  //     step: this.video.step,
  //     sinceWhen: this.stepTimeStamp,
  //   },
  // })
}

function pauseVideo() {
  this.player.pause()
}

function replay() {
  this.player
    .setCurrentTime(this.videoChapters[this.currentVideoIndex].startTime)
    .then(_ => this.player.play())

  const eventTitle = 'Replay'
  const eventProperties = {
    segment: this.video.title,
    step: this.video.prompt,
    kit_type: this.kitType,
    medplum_id: this.medplumOrderId,
    sku: this.$store.getters['user/getSku'],
    customer: this.configKeys ? this.configKeys.carrier : null,
    barcode: this.barcodeId,
    segment_time: Date.now() - this.stepTimeStamp,
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })
}

function resumeVideo() {
  if (!this.canGoNext) {
    this.player.play()
  }
}

function moveVideo(timeDiff) {
  this.player
    .getCurrentTime()
    .then(time => {
      return this.player.setCurrentTime(
        Math.min(
          this.videoChapters[this.currentVideoIndex].endTime,
          Math.max(time + timeDiff, this.videoChapters[this.currentVideoIndex].startTime)
        )
      )
    })
    .then(_ => this.player.play())
}

async function setupVideoPlayer() {
  this.instructions = []
  this.player = new player('video-player', {
    id: this.videoID,
    responsive: true,
    title: false,
    controls: false,
  })

  this.player
    .getChapters()
    .then(chapters => {
      this.videoChapters = chapters

      return this.player.getDuration()
    })
    .then(duration => {
      for (var i = 0; i < this.videoChapters.length - 1; i++) {
        this.videoChapters[i].endTime = this.videoChapters[i + 1].startTime - 0.25
        this.videoChapters[i].durationText = _secToTime(
          this.videoChapters[i].endTime - this.videoChapters[i].startTime
        )
        this.videoChapters[i].startTime += 0.25 // 0.25 sec is the predefined time gap between the chapter start and the first contentful frame of the current chapter
      }

      if (this.currentVideoIndex > 0) {
        this.player.setCurrentTime(this.videoChapters[this.currentVideoIndex].startTime)
      }

      this.videoChapters[0].startTime = 0 // Remove the gap from the first chapter
      let lastIndex = this.videoChapters.length - 1
      this.videoChapters[lastIndex].endTime = duration
      this.videoChapters[lastIndex].durationText = _secToTime(
        duration - this.videoChapters[lastIndex].startTime
      )
    })

  this.player.on('play', () => {
    this.videoPaused = false
    this.showVideoButton = false
    this.showInstructions = false

    if (this.proceededWithoutVideo && this.video.instructions.length > 0) {
      this.video.instructions.forEach(instruction => {
        instruction.image = this.getMediaUrl(instruction.image)
        if (instruction.displayAt == 'undefined') {
          this.$bugsnag.notify(`No timer on instructions in ${this.video.step}`)
        }
      })
    }
  })

  this.player.on('pause', () => {
    this.videoPaused = true
  })

  this.player.on('timeupdate', data => {
    if (data.seconds >= this.videoChapters[this.currentVideoIndex].endTime) {
      this.player.pause()
      this.showInstructions = true
      this.canGoNext = true
    }
    if (this.showVideoButton && !this.videoPaused) {
      this.stepProgressText = `${_secToTime(
        data.seconds - this.videoChapters[this.currentVideoIndex].startTime
      )} / ${this.videoChapters[this.currentVideoIndex].durationText}`
    }

    if (
      this.video.instructions.length > 0 &&
      this.instructionsPointer < this.video.instructions.length &&
      (typeof this.video.instructions[this.instructionsPointer].displayAt == 'undefined' ||
        this.video.instructions[this.instructionsPointer].displayAt <=
          data.seconds - this.videoChapters[this.currentVideoIndex].startTime)
    ) {
      this.instructions.push(this.video.instructions[this.instructionsPointer++])
    }
  })

  this.player.on('error', error => {
    this.player
      .setCurrentTime(this.videoChapters[this.currentVideoIndex].startTime)
      .then(_ => this.video.play())
  })
}

async function startVideo() {
  if (this.medplumOrderId) {
    Vue.axios
      .post('/customer-entered', {
        orderId: this.medplumOrderId,
        isResumingTest: this.showResume,
      })
      .catch(error => {
        console.error('Error in sending mail notification: ', error.message)
        this.$bugsnag.notify(error)
      })
  }

  if (this.showResume) {
    this.player.play()

    const eventTitle = 'ResumedKit'
    const eventProperties = {
      kit_type: this.kitType,
      medplum_id: this.medplumOrderId,
      barcode: this.barcodeId,
      sku: this.$store.getters['user/getSku'],
      customer: this.configKeys ? this.configKeys.carrier : null,
      source: this.$route.query.src,
    }

    AmplitudeAPI.logEvent(eventTitle, {
      ...eventProperties,
      linked_amplitude_id: this.$route.query.q,
    })

    MixpanelAPI.track(eventTitle, {
      ...eventProperties,
      application: 'Collection App',
    })

    this.showResume = false
  }

  this.stepTimeStamp = Date.now()
  // this.initTwilio()
  // if (!this.proceededWithoutVideo) {
  //   this.connectionMonitor = window.setInterval(() => {
  //     if ((this.room == null || this.room.state == 'disconnected') && !this.showWarning) {
  //       this.initTwilio()
  //     } else {
  //       const tracks = [this.localVideoTrack, this.localAudioTrack]
  //       tracks.forEach(track => {
  //         if (
  //           !track.isStarted ||
  //           (track.isStopped && track.mediaStreamTrack.readyState == 'ended')
  //         ) {
  //           this.restartTrack(track)
  //         }
  //       })
  //     }
  //   }, 30000)
  // }
}

function startVideoCall(agentVideo) {
  this.showVideoCallUI = true
  this.$app.showCallConnecting = false
  this.$app.callTimestamp = null
  document.getElementById('support-video-call').appendChild(agentVideo)
  const eventTitle = 'HelpRequestAnswered'
  const eventProperties = {
    segment: this.video.title,
    step: this.video.prompt,
    kit_type: this.kitType,
    medplum_id: this.medplumOrderId,
    sku: this.$store.getters['user/getSku'],
    barcode: this.barcodeId,
    customer: this.configKeys ? this.configKeys.carrier : null,
    segment_time: Date.now() - this.stepTimeStamp,
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })
}

function handleError(error) {
  if (error.name === 'ConstraintNotSatisfiedError') {
    const v = constraints.video
    errorMsg(
      `The resolution ${v.width.exact}x${v.height.exact} px is not supported by your device.`
    )
  } else if (error.name === 'PermissionDeniedError') {
    errorMsg(
      'Permissions have not been granted to use your camera and ' +
        'microphone, you need to allow the page access to your devices in ' +
        'order for the demo to work.'
    )
  }
  errorMsg(`getUserMedia error: ${error.name}`, error)
}

function errorMsg(msg, error) {
  console.log('VideoStage.vue :62', msg)
  if (typeof error !== 'undefined') {
    console.log('VideoStage.vue :64', error)
  }
}

function handleMediaError(error, retryAction) {
  if (error.name == 'NotAllowedError') {
    this.$modal.open('UpdateBrowserPermissions', {
      retry: retryAction,
    })
  } else {
    retryAction()
  }
}

function getMediaTracks() {
  return [
    this.localVideoTrack
      ? Promise.resolve(this.localVideoTrack)
      : createLocalVideoTrack({ facingMode: 'user' }),
    this.localAudioTrack ? Promise.resolve(this.localAudioTrack) : createLocalAudioTrack(),
  ]
}

function initUserVideo() {
  try {
    const userVidEl = this.$refs.userVidWrapper
    userVidEl.appendChild(this.localVideoTrack.attach())
    this.userVideoEl = userVidEl.getElementsByTagName('video')[0]
  } catch (e) {
    handleError(e)
  }
}

function initTwilio() {
  const thisComp = this

  function networkQualityChanged(networkQualityLevel, networkQualityStats) {
    thisComp.showWarning = networkQualityLevel < this.minNetworkQuality
  }

  function canCall(agentID, trackKind) {
    if (!thisComp.callAnswers) {
      thisComp.callAnswers = {
        agentID: agentID,
        video: false,
        audio: false,
        othersAttempted: [],
      }
      thisComp.callAnswers[trackKind] = true
      return true
    } else if (thisComp.callAnswers.agentID == agentID) {
      thisComp.callAnswers[trackKind] = true
      return true
    } else {
      if (!thisComp.callAnswers.othersAttempted.includes(agentID)) {
        thisComp.callAnswers.othersAttempted.push(agentID)
        thisComp.sendMessage({
          messageType: 'line-busy',
          to: agentID,
          data: { inCallWith: thisComp.callAnswers.agentID },
        })
      }

      return false
    }
  }

  function participantConnected(participant) {
    participant.tracks.forEach(publication => {
      if (publication.track) {
        trackSubscribed(publication.track, participant)
      }
    })

    participant.on('trackSubscribed', track => {
      trackSubscribed(track, participant)
    })
    participant.on('trackUnsubscribed', track => {
      trackUnsubscribed(track, participant)
    })
  }

  function trackSubscribed(track, participant) {
    switch (track.kind) {
      case 'data':
        track.on('message', data => {
          switch (JSON.parse(data).messageType) {
            case 'nudge':
              thisComp.nudgeActive = true
              thisComp.pauseVideo()
              thisComp.$modal.open('ConnectToAgent', {
                allowCancel: false,
                title: thisComp.video.title,
                prompt: thisComp.video.prompt,
              })

              // play alert sound
              const audioSrc = require(`@/assets/audio/nudge.mp3`)
              const nudgeSound = new Audio(audioSrc)
              nudgeSound.play()
              break
          }
        })
        thisComp.sendMessage({
          messageType: 'room-status',
          to: participant.identity,
          data: {
            proceededWithoutVideo: thisComp.proceededWithoutVideo,
            kitType: thisComp.kitType,
            medplumId: thisComp.medplumOrderId,
            barcodeId: thisComp.barcodeId,
            callStatus: { sinceWhen: thisComp.$app.callTimestamp },
            stepStatus: {
              title: thisComp.video.title,
              prompt: thisComp.video.prompt,
              step: thisComp.video.step,
              sinceWhen: thisComp.stepTimeStamp,
            },
          },
        })
        break
      case 'video':
        if (canCall(participant.identity, 'video')) {
          if (thisComp.proceededWithoutVideo) {
            thisComp.room.localParticipant.publishTrack(thisComp.localVideoTrack)
          }
          thisComp.startVideoCall(track.attach())
        }
        break
      case 'audio':
        if (canCall(participant.identity, 'audio')) {
          if (thisComp.proceededWithoutVideo) {
            thisComp.room.localParticipant.publishTrack(thisComp.localAudioTrack)
          }
          document.body.appendChild(track.attach())
        }
        break
    }
  }

  function trackUnsubscribed(track, participant) {
    if (thisComp.callAnswers && participant.identity == thisComp.callAnswers.agentID) {
      thisComp.callAnswers[track.kind] = false
      track.detach().forEach(element => element.remove())

      if (!thisComp.callAnswers['video'] && !thisComp.callAnswers['audio']) {
        if (thisComp.proceededWithoutVideo) {
          thisComp.disableLocalTracks()
        }
        thisComp.showVideoCallUI = false
        thisComp.nudgeActive = false
        thisComp.callAnswers = null
      }
    }
  }

  Promise.all(
    this.proceededWithoutVideo
      ? [Promise.resolve(null), Promise.resolve(null)]
      : this.getMediaTracks()
  )
    .then(tracks => {
      if (tracks[0] && !this.localVideoTrack) {
        this.$store.commit('twilio/SET_LOCAL_VIDEO_TRACK', tracks[0])
      }

      if (tracks[1] && !this.localAudioTrack) {
        this.$store.commit('twilio/SET_LOCAL_AUDIO_TRACK', tracks[1])
      }

      if (!this.localDataTrack) {
        this.$store.commit('twilio/SET_LOCAL_DATA_TRACK', new LocalDataTrack())
      }

      if (!this.proceededWithoutVideo) {
        this.initUserVideo()
      }

      const roomName = this.barcodeId ? this.barcodeId : Date.now()

      fetch(`${CC_BASE_API_URL}/comm/token`, {
        method: 'POST',
        body: JSON.stringify({ userName: `user:${roomName}`, roomName: roomName }),
      })
        .then(res => res.clone().json())
        .then(data => {
          runPreflight(data.token).on('failed', (error, report) => {
            this.$bugsnag.notify(error)

            if (this.stopOnPreflightFail) {
              this.$modal.open('Warning', {
                warningTitle: 'Failed to start video session',
                warningText:
                  'Please update your browser/OS to the latest version and try again. If the problem persists, please click below to email us for help.',
                ackBtnText: 'Email Us',
                preventBackgroundTap: true,
                canClose: false,
                callBack: () => {
                  let email = new Email('Preflight test failed', JSON.stringify(report, null, 2), {
                    'Box ID': this.barcodeId,
                    URL: document.URL,
                    UserAgent: navigator.userAgent,
                  })

                  window.open(email.getHref(), '_self')
                },
              })
            }
          })

          return connect(data.token, {
            name: roomName,
            tracks: this.proceededWithoutVideo
              ? [this.localDataTrack]
              : [this.localVideoTrack, this.localAudioTrack, this.localDataTrack],
            bandwidthProfile: {
              video: {
                mode: 'presentation',
                maxSubscriptionBitrate: 2500000,
                dominantSpeakerPriority: 'high',
              },
            },
            dominantSpeaker: true,
            networkQuality: true,
          })
        })
        .then(room => {
          this.room = room
          room.localParticipant.on('networkQualityLevelChanged', networkQualityChanged)
          room.participants.forEach(participantConnected)
          room.on('disconnected', this.roomDisconnected)
          room.on('participantConnected', participantConnected)
        })
        .catch(error => {
          this.showWarning = true
        })
    })
    .catch(error => this.handleMediaError(error, this.initTwilio))
}

function reconnect() {
  if (this.room == null || this.room.state == 'disconnected') {
    this.initTwilio()
  }

  this.showWarning = this.room.localParticipant.networkQualityLevel < this.minNetworkQuality
}

function restartTrack(track) {
  track.restart().catch(error =>
    this.handleMediaError(error, () => {
      this.restartTrack(track)
    })
  )
}

function roomDisconnected(room, error) {
  if (error.code !== 53205) {
    this.initTwilio()
  }
}
</script>
